import NewDate from "../../utils/NewDate";
export default {
  date: NewDate(),
  address: "",
  homePhone: "",
  mobilePhone: "",
  email: "",
  std: false,
  hiv: false,
  mental: false,
  disability: false,
  substanceAbuse: false,
  other: false,
  otherDescription: "",
};
