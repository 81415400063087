import React from "react";
import { Grid } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
const Comments = (props) => {
  const fontSize = 19;
  return (
    <Grid item xs={12} sm={12}>
      <h2>COMMENTS:</h2>
      <TextValidator
        style={{ width: "100%" }}
        fullWidth
        label='Additional comments here..'
        onChange={props.onInputChange}
        name='comments'
        value={props.state.comments}
        validators={["minStringLength:0", "maxStringLength:500"]}
        errorMessages={["This field is required", "Too long"]}
        inputProps={{ style: { fontSize: fontSize } }}
        InputLabelProps={{ style: { fontSize: fontSize } }}
        multiline
      />
    </Grid>
  );
};

export default Comments;
