import NewDate from "../../utils/NewDate";

export default {
  date: NewDate(),
  name: "",
  dob: "",
  streetaddress: "",
  city: "",
  province: "",
  postal: "",
  cellphone: "",
  allergies: "",
  currentMeds: "",
  prevSurg1: "",
  prevSurg2: "",
  prevSurg3: "",
  prevSurg4: "",
  skinCancer: "",
  cancerWhen: "",
  cancerType1: false,
  cancerType2: false,
  cancerType3: false,
  tmj: "",
  constipation: "",
  crohns: "",
  heartDisease: "",
  contactLenses: "",
  hearingAids: "",
  anxiety: "",
  ocd: "",
  cancer: "",
  diabetes: "",
  epilepsy: "",
  hep: "",
  hiv: "",
  lupus: "",
  thyroid: "",
  herpes: "",
  birthControl: "",
  birthLong: "",
  menopause: "",
  preg: "",
  breastfeeding: "",
  ovaries: "",
  hormone: "",
  hyster: "",
  hysterWhen: "",
  derma: "",
  dermaReason: "",
  accutane: false,
  anitbiotics: false,
  retin: false,
  retinStrength: "",
  hydro: false,
  hydroStrength: "",
  medLength: "",
  botox: false,
  resty: false,
  artecoll: false,
  laserSurface: false,
  laserRemove: false,
  thermage: false,
  ipl: false,
  injectAreas: "",
  injectHappy: "",
  facials: false,
  microderm: false,
  chempeel: false,
  lacticPeel: false,
  salicPeel: false,
  lastTreat: "",
  peelHapppy: "",
  occupation: "",
  fullpart: "",
  children: "",
  stress: "",
  stressWell: "",
  sleepHours: "",
  diet: "",
  vitamins: "",
  coffee: "",
  water: "",
  alcohol: "",
  cigs: "",
  cardio: false,
  weights: false,
  stretch: false,
  exerciseOften: "",
  acne: false,
  blackheads: false,
  eczema: false,
  whiteheads: false,
  senskin: false,
  pigment: false,
  rosacea: false,
  congskin: false,
  elastic: false,
  psoriasis: false,
  sundamage: false,
  uneventext: false,
  scars: false,
  darkCircles: false,
  pores: false,
  ros: false,
  vitiligo: false,
  aging: false,
  dermatitis: false,
  freckles: false,
  scarsNoAcne: false,
  backne: false,
  dryskin: false,
  wrinkles: false,
  sebhorrea: false,
  puffy: false,
  capilaries: false,
  concern1: "",
  concern2: "",
  concern3: "",
  sunReact: "",
  darkSkin: "",
  sunblister: "",
  blisterArea: "",
  tanhow: "",
  tanoften: "",
  skincolor: "",
  ethnic: "",
  eyecolor: "",
  haircolor: "",
  promotions: "",
  skinCare: "",
};
