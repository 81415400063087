import NewDate from "../../utils/NewDate";
// export default {
//   date: NewDate(),
//   name: "",
//   dob: "",
//   cough: false,
//   fever: false,
//   throat: false,
//   headache: false,
//   nose: false,
//   sneeze: false,
//   smellTaste: false,
//   breath: false,
//   aches: false,
//   fatigue: false,
//   lesions: false,
//   diarrhea: false,
//   day14Travel: "",
//   closeContact: "",
//   clusterHub: "",
//   covidRisk: "",
//   tested: "",
//   dateOfSwab: "",
//   other: "",
//   verifiedBy: "",
//   updateBy: "",
// };
export default {
  date: NewDate(),
  name: "",
  dob: "",
  cough: false,
  fever: false,
  noFeverCough: false,
  throat: false,
  headache: false,
  nose: false,
  breath: false,
  noSoreThroat: false,
  waitingTestResult: false,
  testedPositive: false,
  closeContact: false,
  day14Travel: false,
  selfIsolating: false,
};
