import React from "react";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";

const Section1 = (props) => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <p
          style={{
            marginTop: 0,
            marginBottom: 20,
            fontStyle: "italic",
            fontSize: 22,
          }}
        >
          In the past 48 hours have you had, or are you currently experiencing:
        </p>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.cough}
            control={
              <Checkbox
                checked={props.state.cough}
                color='primary'
                onChange={props.onCheck}
                name='cough'
              />
            }
            label='New or worsening cough'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.fever}
            control={
              <Checkbox
                checked={props.state.fever}
                color='primary'
                onChange={props.onCheck}
                name='fever'
              />
            }
            label='Fever greater than 38 C (i.e. chills, sweats)'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.noFeverCough}
            control={
              <Checkbox
                checked={props.state.noFeverCough}
                color='primary'
                onChange={props.onCheck}
                name='noFeverCough'
              />
            }
            label='Patient does not have a worsening cough or fever greater than 38 C. NEWWWW UPDATE SERVER'
            labelPlacement='end'
          />
        </Grid>

        <p style={{ marginTop: 40, fontStyle: "italic", fontSize: 22 }}>OR</p>
        <p style={{ marginTop: 40, fontStyle: "italic", fontSize: 22 }}>
          Have you had two or more of the following symptoms (new or worsening):
        </p>

        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.throat}
            control={
              <Checkbox
                checked={props.state.throat}
                color='primary'
                onChange={props.onCheck}
                name='throat'
              />
            }
            label='Sore throat'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.headache}
            control={
              <Checkbox
                checked={props.state.headache}
                color='primary'
                onChange={props.onCheck}
                name='headache'
              />
            }
            label='Headache'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.nose}
            control={
              <Checkbox
                checked={props.state.nose}
                color='primary'
                onChange={props.onCheck}
                name='nose'
              />
            }
            label='Runny nose'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.breath}
            control={
              <Checkbox
                checked={props.state.breath}
                color='primary'
                onChange={props.onCheck}
                name='breath'
              />
            }
            label='Shortness of breath'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.noSoreThroat}
            control={
              <Checkbox
                checked={props.state.noSoreThroat}
                color='primary'
                onChange={props.onCheck}
                name='noSoreThroat'
              />
            }
            label='Patient does not have a sore throat, headache, runny nose or shortness of breath NEWWWW UPDATE SERVER'
            labelPlacement='end'
          />
        </Grid>
        <p style={{ marginTop: 40, fontStyle: "italic", fontSize: 22 }}>
          If you meet the above screening criteria, please call 811 to arrange
          Covid-19 testing and also notify our office.
        </p>

        <p style={{ fontStyle: "italic", fontSize: 22 }}>
          If you are under self-isolation requirements:
        </p>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.waitingTestResult}
            control={
              <Checkbox
                checked={props.state.waitingTestResult}
                color='primary'
                onChange={props.onCheck}
                name='waitingTestResult'
              />
            }
            label='Are you waiting for Covid-19 test results?'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.testedPositive}
            control={
              <Checkbox
                checked={props.state.testedPositive}
                color='primary'
                onChange={props.onCheck}
                name='testedPositive'
              />
            }
            label='Have you tested positive for Covid-19?'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.closeContact}
            control={
              <Checkbox
                checked={props.state.closeContact}
                color='primary'
                onChange={props.onCheck}
                name='closeContact'
              />
            }
            label='Have you had close contact with someone who has or is suspected to have Covid-19?'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.day14Travel}
            control={
              <Checkbox
                checked={props.state.day14Travel}
                color='primary'
                onChange={props.onCheck}
                name='day14Travel'
              />
            }
            label='Have you travelled outside of Atlantic Canada within the last 14 days?'
            labelPlacement='end'
          />
        </Grid>
        <Grid item xs={12} sm={12} className='checkBoxes'>
          <FormControlLabel
            value={props.state.selfIsolating}
            control={
              <Checkbox
                checked={props.state.selfIsolating}
                color='primary'
                onChange={props.onCheck}
                name='selfIsolating'
              />
            }
            label='Patient is not under self-isolation requirements'
            labelPlacement='end'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Section1;
